import {createApp} from 'vue';
import App from './App.vue';
import {router} from './router';
import './assets/scss/app.scss';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {store} from './store';
import {init, vueRouterInstrumentation} from '@sentry/vue';
import {Integrations} from '@sentry/tracing';
import {
	faBriefcase,
	faCalculator,
	faDownload,
	faPdf,
	faPlusRound,
	faPresentation,
	faSignOut,
	faFilter,
	faArrowRight,
} from './icons';

init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	release: import.meta.env.VITE_SENTRY_RELEASE,
	environment: import.meta.env.MODE,
	integrations: [
		new Integrations.BrowserTracing({
			routingInstrumentation: vueRouterInstrumentation(router),
			tracingOrigins: [/https?:\/\/maison-energie\.viteos\.ch\//, import.meta.env.VITE_API_BASE_URI],
		}),
	],
	tracesSampleRate: 0.5,
});

library.add(fas);
library.add(
	faPlusRound,
	faSignOut,
	faPresentation,
	faBriefcase,
	faCalculator,
	faPdf,
	faDownload,
	faFilter,
	faArrowRight,
);

createApp(App)
	.component('font-awesome-icon', FontAwesomeIcon)
	.directive('click-outside', {
		mounted(el, binding) {
			el.clickOutsideEvent = function (event: Event) {
				// here I check that click was outside the el and his childrens
				if (!(el == event.target || el.contains(event.target))) {
					// and if it did, call method provided in attribute value
					binding.value(event, el);
				}
			};
			document.body.addEventListener('click', el.clickOutsideEvent);
		},
		unmounted(el) {
			document.body.removeEventListener('click', el.clickOutsideEvent);
		},
	})
	.use(router)
	.use(store)
	.mount('#app');
