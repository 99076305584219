<template>
	<section id="presentation" :style="contextStyle" ref="container">
    <ZonePoint
        v-for="service of services"
        :key="service.id"
        :point="service.acf.points_interet[0]"
        :service="service"
        @documentAdded="onDocumentAdded"
    />
		<Snackbar v-model:show="displaySnackbar" :duration="4000" @closed="onSnackbarClosed">
			<span v-if="latestDocument">Document {{ latestDocument.name }} ajouté</span>
			<button class="btn btn-link" type="button" @click="cancelOnDocumentClick">Annuler</button>
		</Snackbar>
	</section>
</template>

<script lang="ts">
import {Service, SharePointDocument} from 'src/typings/entities';
import {defineComponent, PropType} from 'vue';
import ZonePoint from './ZonePoint.vue';
import Snackbar from '@/components/Snackbar/Snackbar.vue';

interface ComponentData {
	containerWidth: number;
	containerHeight: number;
	displaySnackbar: boolean;
	latestDocument: SharePointDocument | null;
}

interface ServiceBackground {
	url: string;
	width: number;
	height: number;
}

export default defineComponent({
	name: 'Presentation',

	components: {
		ZonePoint,
		Snackbar,
	},

	props: {
		services: {
			type: Array as PropType<Array<Service>>,
			required: true,
		},
	},

	data(): ComponentData {
		return {
			containerWidth: 500,
			containerHeight: 500,
			displaySnackbar: false,
			latestDocument: null,
		};
	},

	mounted(): void {
		this.onContainerResize();
		window.addEventListener('resize', this.onContainerResize);
	},

	unmounted(): void {
		window.removeEventListener('resize', this.onContainerResize);
	},

	methods: {
		onContainerResize(): void {
			this.containerWidth = (this.$refs.container as HTMLElement).clientWidth;
			this.containerHeight = (this.$refs.container as HTMLElement).clientHeight;
		},
		onDocumentAdded(document: SharePointDocument): void {
			this.latestDocument = document;
			this.displaySnackbar = true;
		},
		onSnackbarClosed(): void {
			this.latestDocument = null;
		},
		cancelOnDocumentClick(): void {
			this.$store.commit('removeFromCart', this.latestDocument);
			this.displaySnackbar = false;
			this.latestDocument = null;
		},
	},

	computed: {
		background(): ServiceBackground {
			switch (this.$route.params.type) {
				case 'chaleur':
					return {
						url: '/img/house/house_chaleur.webp',
						width: 3024,
						height: 2160 ,
					};
				default:
					return {
						url: '/img/house/house_pac.webp',
						width: 2657,
						height: 1993,
					};
			}
		},
		contextStyle(): object {
			return {
				'backgroundImage': `url('${this.background.url}')`,
				'--zone-original-height': this.background.height,
				'--zone-original-width': this.background.width,
				'--zone-height': this.containerHeight,
				'--zone-width': this.containerWidth,
			};
		},
	},
});
</script>

<style>
#presentation {
	background-position: top center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 100%;
	height: auto;
	position: relative;
	/* This is the ratio of the displayed image */
	aspect-ratio: 2657 / 1993;
	--zone-original-height: 1;
	--zone-original-width: 1;
	--zone-height: 1;
	--zone-width: 1;

}

</style>
