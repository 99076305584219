<template>
	<section id="splashscreen">
		<div class="text-center splashscreen-sidebar">
				<img src="../assets/viteos.svg" alt="Viteos" class="img-fluid splashscreen-logo" />
		</div>
		<div class="splashscreen-cover"></div>
		<div class="text-end splashscreen-footer">
			<button class="btn btn-primary btn-lg btn-rounded" type="button" @click="startVisit">
				Démarrer la visite
			</button>
		</div>
	</section>
</template>

<script lang="ts">
import Input from '@/components/Form/Input.vue';
import {Modal} from 'mdb-ui-kit';
import {defineComponent} from 'vue';

interface BaseComponentData {
	loading: boolean;
	errorMessage: string | null;
}

interface ErrorResponse {
	code: number;
	message: string;
}

export default defineComponent({
	name: 'SplashScreen',

	components: {
		'mdb-input': Input,
	},

	data(): BaseComponentData {
		return {
			loading: false,
			errorMessage: null,
		};
	},

	methods: {
		startVisit(): void {
      this.$store.commit('login', {email : 'viteos@viteos.ch'});
      this.$router.push({path: '/dashboard'});
		},
	},
});
</script>

<style lang="scss">
#splashscreen {
	display: grid;
	grid-template-columns: 300px 1fr;
	grid-template-rows: 1fr 170px;
	grid-template-areas:
		'sb co'
		'sb ft';
	gap: 0px 0px;
	height: 100%;
	padding: 3rem 1rem 0 1rem;
}

.splashscreen-sidebar {
	grid-area: sb;
}

.splashscreen-cover {
	background-image: url('../assets/eco_house.webp');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	grid-area: co;
}

.splashscreen-logo {
	width: 208px;
}

.splashscreen-footer {
	grid-area: ft;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	.btn-primary {
		margin-right: 6rem;
	}
}
</style>
