import {IconDefinition, IconName, IconPrefix} from '@fortawesome/fontawesome-common-types';

const prefix: IconPrefix = 'fav' as IconPrefix;

export const faPlusRound: IconDefinition = {
	prefix,
	iconName: 'plus-round' as IconName,
	icon: [
		30,
		30,
		[],
		'e001',
		'M16.4166 7.91683H13.5833V13.5835H7.91659V16.4168H13.5833V22.0835H16.4166V16.4168H22.0833V13.5835H16.4166V7.91683ZM14.9999 0.833496C7.17992 0.833496 0.833252 7.18016 0.833252 15.0002C0.833252 22.8202 7.17992 29.1668 14.9999 29.1668C22.8199 29.1668 29.1666 22.8202 29.1666 15.0002C29.1666 7.18016 22.8199 0.833496 14.9999 0.833496ZM14.9999 26.3335C8.75242 26.3335 3.66659 21.2477 3.66659 15.0002C3.66659 8.75266 8.75242 3.66683 14.9999 3.66683C21.2474 3.66683 26.3333 8.75266 26.3333 15.0002C26.3333 21.2477 21.2474 26.3335 14.9999 26.3335Z',
	],
};

export const faSignOut: IconDefinition = {
	prefix,
	iconName: 'sign-out' as IconName,
	icon: [
		18,
		18,
		[],
		'e002',
		'M7.09 12.59L8.5 14L13.5 9L8.5 4L7.09 5.41L9.67 8H0V10H9.67L7.09 12.59ZM16 0H2C0.89 0 0 0.9 0 2V6H2V2H16V16H2V12H0V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z',
	],
};

export const faPresentation: IconDefinition = {
	prefix,
	iconName: 'presentation' as IconName,
	icon: [
		21,
		20,
		[],
		'e003',
		[
			'M9.1123 0H11.4996V2.77931H9.1123V0Z',
			'M20.376 3.28267C20.376 2.86433 19.9765 2.5249 19.4843 2.5249H1.32294C0.829449 2.5249 0.431152 2.86433 0.431152 3.28267V4.93096H1.63606V15.0124H7.18967L4.31517 18.4417C4.11352 18.6446 4.18867 19.0492 4.48551 19.346L5.02409 19.8834L9.19117 15.1614V19.9999H11.5096V15.0324L15.7919 19.8821L16.3292 19.3448C16.626 19.0492 16.7024 18.6434 16.4995 18.4405L13.5374 15.0111H19.0559V4.92971H20.3785V3.28267H20.376ZM17.9186 13.9102H2.79839V4.93096H17.9186V13.9102Z',
		],
	],
};

export const faBriefcase: IconDefinition = {
	prefix,
	iconName: 'briefcase' as IconName,
	icon: [
		20,
		20,
		[],
		'e004',
		'M18 4.01914H14V2.00957C14 0.894258 13.11 0 12 0H8C6.89 0 6 0.894258 6 2.00957V4.01914H2C0.89 4.01914 0.00999999 4.9134 0.00999999 6.02871L0 17.0813C0 18.1967 0.89 19.0909 2 19.0909H18C19.11 19.0909 20 18.1967 20 17.0813V6.02871C20 4.9134 19.11 4.01914 18 4.01914ZM12 4.01914H8V2.00957H12V4.01914Z',
	],
};

export const faCalculator: IconDefinition = {
	prefix,
	iconName: 'calculator' as IconName,
	icon: [
		14,
		20,
		[],
		'e005',
		'M1.98758 0H11.9255C12.4526 0 12.9582 0.210714 13.3309 0.585786C13.7036 0.960859 13.913 1.46957 13.913 2V18C13.913 18.5304 13.7036 19.0391 13.3309 19.4142C12.9582 19.7893 12.4526 20 11.9255 20H1.98758C1.46044 20 0.954891 19.7893 0.582148 19.4142C0.209405 19.0391 0 18.5304 0 18V2C0 1.46957 0.209405 0.960859 0.582148 0.585786C0.954891 0.210714 1.46044 0 1.98758 0ZM1.98758 2V6H11.9255V2H1.98758ZM1.98758 8V10H3.97516V8H1.98758ZM5.96273 8V10H7.95031V8H5.96273ZM9.93789 8V10H11.9255V8H9.93789ZM1.98758 12V14H3.97516V12H1.98758ZM5.96273 12V14H7.95031V12H5.96273ZM9.93789 12V14H11.9255V12H9.93789ZM1.98758 16V18H3.97516V16H1.98758ZM5.96273 16V18H7.95031V16H5.96273ZM9.93789 16V18H11.9255V16H9.93789Z',
	],
};

export const faPdf: IconDefinition = {
	prefix,
	iconName: 'pdf' as IconName,
	icon: [
		20,
		20,
		[],
		'e006',
		'M18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM9.5 7.5C9.5 8.33 8.83 9 8 9H7V11H5.5V5H8C8.83 5 9.5 5.67 9.5 6.5V7.5ZM14.5 9.5C14.5 10.33 13.83 11 13 11H10.5V5H13C13.83 5 14.5 5.67 14.5 6.5V9.5ZM18.5 6.5H17V7.5H18.5V9H17V11H15.5V5H18.5V6.5ZM7 7.5H8V6.5H7V7.5ZM2 4H0V18C0 19.1 0.9 20 2 20H16V18H2V4ZM12 9.5H13V6.5H12V9.5Z',
	],
};

export const faDownload: IconDefinition = {
	prefix,
	iconName: 'download' as IconName,
	icon: [
		18,
		22,
		[],
		'e007',
		'M18 7.76471H12.8571V0H5.14286V7.76471H0L9 16.8235L18 7.76471ZM0 19.4118V22H18V19.4118H0Z',
	],
};

export const faFilter: IconDefinition = {
	prefix,
	iconName: 'filter' as IconName,
	icon: [
		22,
		14,
		[],
		'e008',
		'M8.66667 13.5H13.3333V11.3333H8.66667V13.5ZM0.5 0.5V2.66667H21.5V0.5H0.5ZM4 8.08333H18V5.91667H4V8.08333Z',
	],
};

export const faArrowRight: IconDefinition = {
	prefix,
	iconName: 'arrow-right' as IconName,
	icon: [19, 8, [], 'e009', 'M19 4L15 0V3H0V5H15V8L19 4Z'],
};
