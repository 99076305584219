import {captureMessage, setUser} from '@sentry/minimal';
import jwtDecode from 'jwt-decode';
import {createStore} from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {Category, HydraCollection, Service, SharePointDocument, SharePointFolder, User} from './typings/entities';

export interface State {
	user: User | null;
	services: Service[];
	categories: Category[];
	cart: SharePointDocument[];
	folders: SharePointFolder[];
	singleDocuments: Map<string, SharePointDocument>;
	inactionModalIsShowing: boolean;
}

export interface TokenResponse {
	token: string;
	refresh_token: string;
}

export const store = createStore<State>({
	plugins: [
		createPersistedState({
			key: 'viteos',
			paths: ['user'],
		}),
	],

	strict: import.meta.env.DEV,

	state() {
		return {
			user: null,
			services: [],
			categories: [],
			cart: [],
			folders: [],
			singleDocuments: new Map<string, SharePointDocument>(),
			inactionModalIsShowing: false,
		};
	},

	mutations: {
		toggleInactionModalStateTo(state: State, payload: boolean) {
			state.inactionModalIsShowing = payload;
		},
		login(state: State, payload: User): void {
			state.user = payload;

			setUser({
				email: payload.email,
				username: payload.email,
				// id: payload.id.toString(),
				// username: payload.username,
			});
		},
		logout(state: State): void {
			state.user = null;

			setUser(null);
			sessionStorage.removeItem('refresh_token');
		},
		setServices(state: State, services: Service[]): void {
			state.services = services.sort((a, b) => {
				if (a.acf.ordre > b.acf.ordre) {
					return 1;
				} else if (a.acf.ordre < b.acf.ordre) {
					return -1;
				}

				return 0;
			});
		},
		addService(state: State, service: Service): void {
			const index = state.services.findIndex(s => s.slug === service.slug);

			if (index >= 0) {
				state.services.splice(index, 1, service);
			} else {
				state.services.push(service);
			}

			state.services = state.services.sort((a, b) => {
				if (a.acf.ordre > b.acf.ordre) {
					return 1;
				} else if (a.acf.ordre < b.acf.ordre) {
					return -1;
				}

				return 0;
			});
		},
		setCategories(state: State, categories: Category[]): void {
			state.categories = categories.sort((a: Category, b: Category) => {
				return a.id - b.id;
			});
		},
		addCategory(state: State, category: Category): void {
			const index = state.categories.findIndex(c => c.id === category.id);

			if (index >= 0) {
				state.categories.splice(index, 1, category);
			} else {
				state.categories.push(category);
			}
		},
		addToCart(state: State, payload: SharePointDocument): void {
			// If the document is already in the cart, skip it.
			if (state.cart.includes(payload)) {
				return;
			}

			state.cart.push(payload);
		},
		removeFromCart(state: State, payload: SharePointDocument): void {
			const index = state.cart.indexOf(payload);

			if (index >= 0) {
				state.cart.splice(index, 1);
			}
		},
		cleanCart(state: State): void {
			state.cart.splice(0, state.cart.length);
		},
		setFolders(state: State, folders: SharePointFolder[]): void {
			state.folders = folders;
		},
		replaceFolder(state: State, folder: SharePointFolder): void {
			const index = state.folders.findIndex(f => f.uuid === folder.uuid);

			if (index >= 0) {
				state.folders.splice(index, 1, folder);
			} else {
				console.warn('Could not find folder matching given uuid', folder.uuid);
			}
		},
		setSingleDocument(state: State, {key, document}: {key: string; document: SharePointDocument}): void {
			state.singleDocuments.set(key, document);
		},
	},

	actions: {
		async loadServices({state, commit, dispatch}): Promise<Service[]> {
			if (state.services.length > 0) {
				return state.services;
			}

			const services = await fetch(`${import.meta.env.VITE_WP_API_BASE_URI}/wp-json/wp/v2/services?per_page=50`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
				},
				credentials: 'same-origin',
			})
				.then(response => {
					return response.json();
				})
				.then((services: Service[]) => {
					const formattedServices: Service[] = services.map(s => {
						s.acf.points_interet.map(p => {
							p.x = parseInt(p.x as unknown as string, 10);
							p.y = parseInt(p.y as unknown as string, 10);

							return p;
						});
						s.acf.ordre = parseInt(s.acf.ordre as unknown as string, 10);

						s.date = new Date(s.date as unknown as string);
						s.date_gmt = new Date(s.date_gmt as unknown as string);
						s.modified = new Date(s.modified as unknown as string);
						s.modified_gmt = new Date(s.modified_gmt as unknown as string);

						return s;
					});

					commit('setServices', formattedServices);

					return formattedServices;
				});

			await dispatch('loadCategories');

			return services;
		},
		async loadService({state, commit, dispatch}, slug: string): Promise<Service | null> {
			const exists = state.services.find(s => s.slug === slug);

			if (exists !== undefined) {
				return exists;
			}

			const services = await fetch(
				`${import.meta.env.VITE_WP_API_BASE_URI}/wp-json/wp/v2/services?slug=${slug}`,
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
					},
					credentials: 'same-origin',
				},
			)
				.then(response => {
					return response.json();
				})
				.then((services: Service[]) => {
					const formattedServices: Service[] = services.map(s => {
						s.acf.points_interet.map(p => {
							p.x = parseInt(p.x as unknown as string, 10);
							p.y = parseInt(p.y as unknown as string, 10);

							return p;
						});
						s.acf.ordre = parseInt(s.acf.ordre as unknown as string, 10);

						s.date = new Date(s.date as unknown as string);
						s.date_gmt = new Date(s.date_gmt as unknown as string);
						s.modified = new Date(s.modified as unknown as string);
						s.modified_gmt = new Date(s.modified_gmt as unknown as string);

						return s;
					});

					commit('addService', formattedServices);

					return formattedServices;
				});

			const existingIds: number[] = state.categories.map(c => c.id);
			const categories = services
				.map(s => s.categories)
				.reduce((acc, ids) => {
					return acc.concat(...ids);
				}, [])
				.filter(id => existingIds.indexOf(id) === -1)
				.map(id => dispatch('loadCategory', id));

			const data: Category[] = await Promise.all(categories);

			for (const c of data) {
				commit('addCategory', c);
			}

			return services[0];
		},
		async loadCategories({state, commit}): Promise<Category[]> {
			if (state.categories.length > 0) {
				return state.categories;
			}

			return await fetch(`${import.meta.env.VITE_WP_API_BASE_URI}/wp-json/wp/v2/categories?per_page=20`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
				},
				credentials: 'same-origin',
			})
				.then(response => response.json())
				.then((categories: Category[]) => {
					commit('setCategories', categories);

					return categories;
				});
		},
		async loadCategory({state, commit}, id: number): Promise<Category> {
			const exists = state.categories.find(c => c.id === id);
			if (exists !== undefined) {
				return exists;
			}

			return await fetch(`${import.meta.env.VITE_WP_API_BASE_URI}/wp-json/wp/v2/categories/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
				},
				credentials: 'same-origin',
			})
				.then(response => response.json())
				.then((category: Category) => {
					commit('addCategory', category);

					return category;
				});
		},
		async loadFolders({commit}): Promise<HydraCollection<SharePointFolder>> {
			const response = await fetch(`${import.meta.env.VITE_API_BASE_URI}/api/share-point-folders`, {
				method: 'GET',
				headers: {
					Accept: 'application/ld+json',
				},
			});

			const folders: HydraCollection<SharePointFolder> = await response.json();

			commit('setFolders', folders['hydra:member']);

			return folders;
		},
		async loadFoldersForService(
			{commit, state, dispatch},
			service: Pick<Service, 'title'>,
		): Promise<SharePointFolder | null> {
			// Try to find the zone folder
			let serviceFolder = state.folders.find(f => {
				return f.name.toLowerCase().includes(service.title.rendered.toLowerCase());
			});

			if (serviceFolder === undefined) {
				// Try to load all root folders first
				await dispatch('loadFolders');

				// Check again
				serviceFolder = state.folders.find(f => {
					return f.name.toLowerCase().includes(service.title.rendered.toLowerCase());
				});

				if (serviceFolder === undefined) {
					console.warn('Could not find a folder matching the current zone');
					return null;
				}
			}

			const response = await fetch(
				`${import.meta.env.VITE_API_BASE_URI}/api/share-point-folders/${serviceFolder.base64ServerRelativeUrl}`,
				{
					method: 'GET',
					headers: {
						Accept: 'application/ld+json',
					},
				},
			);

			const folder: SharePointFolder = await response.json();

			commit('replaceFolder', folder);

			return folder;
		},
		async loadDocumentInCart({commit, state}, base64ServerRelativeUrl: string): Promise<SharePointDocument> {
			if (state.singleDocuments.has(base64ServerRelativeUrl)) {
				const singleDocument = state.singleDocuments.get(base64ServerRelativeUrl) as SharePointDocument;

				if (state.cart.some(d => d.base64ServerRelativeUrl === singleDocument.base64ServerRelativeUrl)) {
					return singleDocument;
				}

				commit('addToCart', singleDocument);
				return singleDocument;
			}

			return await fetch(
				`${import.meta.env.VITE_API_BASE_URI}/api/share-point-documents/${base64ServerRelativeUrl}`,
				{
					method: 'GET',
					headers: {
						'Accept': 'application/ld+json',
						'Content-Type': 'application/ld+json',
					},
				},
			)
				.then(response => {
					if (!response.ok) {
						throw new Error(response.statusText);
					}

					return response.json();
				})
				.then((document: SharePointDocument) => {
					commit('setSingleDocument', {
						key: base64ServerRelativeUrl,
						document: document,
					});
					commit('addToCart', document);
					return document;
				});
		},
		async refreshToken({commit}, token: string): Promise<void> {
			const response = await fetch(`${import.meta.env.VITE_API_BASE_URI}/api/token/refresh`, {
				method: 'POST',
				body: JSON.stringify({
					refresh_token: token,
				}),
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			});

			const json = await response.json();

			// Handle error
			if (!response.ok) {
				let errorMessage: string;
				if ('message' in json) {
					errorMessage = json.message;
				} else {
					errorMessage = response.statusText;
				}

				if (response.status > 499) {
					captureMessage(response.statusText);
				}

				throw new Error(errorMessage);
			}

			// Decode JWT token
			const payload = jwtDecode<User>((json as TokenResponse).token);

			commit('login', payload);
			sessionStorage.setItem('refresh_token', (json as TokenResponse).refresh_token);
		},
	},

	getters: {
		inactionModalIsShowing(state: State): boolean {
			return state.inactionModalIsShowing;
		},
		isAuthenticated(state: State): boolean {
			return state.user !== null;

			// if (state.user === null || state.user.exp === null) {
			// 	return false;
			// }

			// const expiration: number = state.user.exp as number;
			// const expiresAt = new Date(expiration * 1000);
			// const now = new Date();
			// const expired = now > expiresAt;

			// return !expired;
		},
		getServiceInCategoryBySlug:
			state =>
			(slug: string): Service[] => {
				const service = state.services.find(s => s.slug === slug);

				if (service === undefined) {
					return [];
				}

				return state.services.filter(s => {
					if (s.categories === undefined) {
						return false;
					}

					return service.categories.some(c => s.categories.includes(c));
				});
			},
		getService:
			state =>
			(slug: string): Service | undefined => {
				return state.services.find(s => s.slug === slug);
			},
		getCategoryColor:
			state =>
			(categoryId: number): string => {
				//categories don't have a color so we return the color of the first service matching the category
				const firstServiceOfCateg: Service | undefined = state.services.find(
					serv => serv.categories[0] === categoryId,
				);
				return firstServiceOfCateg?.acf.couleur ?? '#eccd21';
			},
		getServicesOfCategory:
			state =>
			(categorySlug: string): Service[] | undefined => {
				const category = state.categories.find(categ => categ.slug === categorySlug);
				const categoryId = category?.id;
				return state.services.filter(service => service.categories[0] === categoryId);
			},
		getCategory:
			state =>
			(id: number): Category | undefined => {
				return state.categories.find(c => c.id === id);
			},
		isDocumentInCart:
			state =>
			(base64ServerRelativeUrl: string): boolean => {
				return state.cart.some(d => d.base64ServerRelativeUrl === base64ServerRelativeUrl);
			},
		getFolderForService:
			state =>
			(name: string): SharePointFolder | undefined => {
				return state.folders.find(f => f.name.toLowerCase().includes(name.toLowerCase()));
			},
	},
});
