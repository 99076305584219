<template>
  <div v-click-outside="onClickAway">
    <div class="zone-point" :style="positionStyle">
      <div
          class="zone-point-btn ripple ripple-surface"
          type="button"
          @click.prevent="toggle"
          ref="btn"
          aria-label="Afficher les informations"
          :class="{active: open}"
          :aria-pressed="open ? 'true' : 'false'"
      >
        <img class="zone-point-icon" :src="getCustomIcon(service.slug)" :alt="service.slug" />
      </div>
    </div>
    <div class="card zone-point-detail-card" ref="card" :class="{hidden: !open}">
      <img v-if="point.image_url" :src="point.image_url" :alt="point.titre" class="card-img-top"/>
      <div class="card-body pb-0">
        <h6 class="card-title text-primary" v-if="point.categorie">{{ point.categorie }}</h6>
        <h5 class="card-title">{{ point.titre }}</h5>
        <div class="alert alert-danger mt-2 mb-0" role="alert" v-if="error">{{ error }}</div>
      </div>
      <div class="card-body pt-0" v-if="selectedCardMenu === 'presentation'">
        <p class="card-text" v-html="point.description"></p>
        <div class="alert alert-danger mt-2 mb-0" role="alert" v-if="error">{{ error }}</div>
      </div>
      <div class="simplified-documents"
           v-show="selectedCardMenu === 'documents'"
      >
        <SimplifiedDocuments
            :service="service"
            @showDocument="(url) => urlOfDocumentToShow = url"
            @documentsLoaded="updatePopperPosition()"
            @showCart="showCart"
        />
      </div>
      <div class="" v-if="selectedCardMenu === 'cart'">
        <SimplifiedCart
        @closeCart="closeCart"
        />
      </div>
      <div v-if="selectedCardMenu !== 'cart'"
          class="card-action mb-3 mt-5">
        <ul class="nav flex-nowrap justify-content-start">
          <li class="nav-item">
            <button
                class="viteos-nav ripple ripple-surface p-2 pt-3 pb-3 ms-2 me-2"
                :class="{active: selectedCardMenu === 'presentation'}"
                type="button"
                @click="selectedCardMenu = 'presentation'"
            >
              <font-awesome-icon :icon="['fav', 'presentation']"></font-awesome-icon>
              <span>Présentation</span>
            </button>
          </li>
          <li class="nav-item">
            <button
                class="viteos-nav ripple ripple-surface p-2 pt-3 pb-3  ms-2 me-2"
                :class="{active: selectedCardMenu === 'documents'}"
                type="button"
                @click="showDocuments"
            >
              <font-awesome-icon icon="file-alt"></font-awesome-icon>
              <span>Documents</span>
            </button>
          </li>
          <li class="nav-item" v-if="service.acf.calculateur_url">
            <button
                class="viteos-nav ripple ripple-surface p-2 pt-3 pb-3  ms-2 me-2"
                type="button"
                @click="showCalculator = true"
            >
              <font-awesome-icon :icon="['fav', 'calculator']"></font-awesome-icon>
              <span>Calculateur</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <IFramePopup
        v-if="showCalculator"
        @close="closeIFrame"
        :src_url="service.acf.calculateur_url"/>
    <IFramePopup v-if="urlOfDocumentToShow !== ''"
                     :src_url="urlOfDocumentToShow"
                     @close="closeIFrame"/>
  </div>
</template>

<script lang="ts">
import {InterestPoint, Service, SharePointDocument} from 'src/typings/entities';
import {base64Encode} from '../../utils';
import {defineComponent, PropType} from 'vue';
import {mapGetters} from 'vuex';
import {createPopper, Instance} from '@popperjs/core';
import CalculatorFrame from "../Other/IFramePopup.vue";
import SimplifiedDocuments from "./SimplifiedDocuments.vue";
import IFramePopup from "../Other/IFramePopup.vue";
import SimplifiedCart from "./SimplifiedCart.vue";

interface ComponentData {
  open: boolean;
  card?: Instance;
  loading: boolean;
  error: string | undefined;
  showCalculator: boolean;
  selectedCardMenu: String;
  urlOfDocumentToShow: String;
}

export default defineComponent({
  name: 'ZonePoint',
  components: {SimplifiedCart, IFramePopup, SimplifiedDocuments, CalculatorFrame},
  props: {
    point: {
      type: Object as PropType<InterestPoint>,
      required: true,
    },
    service: {
      type: Object as PropType<Service>,
      required: true,
    }
  },

  emits: ['opened', 'closed', 'documentAdded'],

  data(): ComponentData {
    return {
      open: false,
      card: undefined,
      loading: false,
      error: undefined,
      showCalculator: false,
      selectedCardMenu: 'presentation',
      urlOfDocumentToShow: '',
    };
  },

  mounted(): void {
    this.card = createPopper(this.$refs.btn as HTMLElement, this.$refs.card as HTMLElement, {
      placement: 'right',
      strategy: 'absolute',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['left'],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            padding: 30
          },
        }
      ],
    });
  },

  unmounted(): void {
    this.open = false;
    this.error = undefined;
    this.card?.destroy();
  },

  methods: {
    showDocuments() {
      this.selectedCardMenu = 'documents';
      this.updatePopperPosition();
    },
    showCart() {
      // timeout to prevent click outside to fire
      setTimeout(() => {
        this.selectedCardMenu = 'cart';
        this.updatePopperPosition()
      }, 50)
    },
    closeCart() {
      setTimeout(() => {
        this.selectedCardMenu = 'presentation';
        this.updatePopperPosition()
      }, 50)
    },
    updatePopperPosition() {
      setTimeout(() => {
        this.card?.forceUpdate();
      })
    },
    toggle(): void {
      this.open = !this.open;
      this.card?.update();

      if (this.open) {
        this.$emit('opened');
      } else {
        this.error = undefined;
        this.$emit('closed');
        this.$nextTick(() => this.selectedCardMenu = "presentation");
      }
    },
    onClickAway(): void {
      const calculatorShowing = this.showCalculator;
      const documentShowing = this.urlOfDocumentToShow !== "";
      const inactionModalShowing = this.$store.getters.inactionModalIsShowing;
      if (this.open
          && !calculatorShowing
          && !documentShowing
          && !inactionModalShowing) {
        this.toggle();
      }
    },
    closeIFrame() {
      //Timeout as onClickAway is run after closeIFrame (closes Zonepoint pannel if iFrame is closed)
      setTimeout(() => {
        this.showCalculator = false;
        this.urlOfDocumentToShow = '';
      },50)
    },
    addDocumentToCart(base64ServerRelativeUrl: string | null): void {
      if (base64ServerRelativeUrl === null) {
        return;
      }

      this.loading = true;
      this.$store
          .dispatch('loadDocumentInCart', base64ServerRelativeUrl)
          .then((document: SharePointDocument) => {
            this.loading = false;
            this.$emit('documentAdded', document);
          })
          .catch(() => {
            this.error = `Le document n'a pas pu être ajouté au panier. Il a peut être été supprimé ou déplacé du serveur SharePoint.`;
            this.loading = false;
          });
    },
    getCustomIcon(code: string): string {
      switch (code.toLowerCase()) {
        case 'certification':
          return '/img/icons/ce.svg';
        case 'borne-electrique':
          return '/img/icons/mo.svg';
        case 'securite':
          return '/img/icons/se.svg';
        case 'domotique':
          return '/img/icons/do.svg';
        case 'installation-interieur':
          return '/img/icons/ii.svg';
        case 'chauffage-a-distance':
          return '/img/icons/cad.svg';
        case 'gaz-naturel':
          return '/img/icons/gn.svg';
        case 'batterie-de-stockage':
          return '/img/icons/bds.svg';
        case 'boiler':
          return '/img/icons/bo.svg';
        case 'panneaux-thermiques':
          return '/img/icons/pt.svg';
        case 'panneaux-photovoltaiques':
          return '/img/icons/pp.svg';
        case 'pompe-a-chaleur':
          return '/img/icons/pac.svg';
        case 'panneaux_solaires':
          return '/img/icons/ps.svg';
        case 'chaleur':
          return '/img/icons/ch.svg';
        case 'mobilite':
          return '/img/icons/mo.svg';
        default:
          return '/img/icons/ps.svg';
      }
    },
  },

  computed: {
    positionStyle(): object {
      return {
        '--zone-point-x': `${this.point.x}px`,
        '--zone-point-y': `${this.point.y}px`,
      };
    },
    base64EncodedUrl(): string | null {
      if (this.point.file_url) {
        return base64Encode(this.point.file_url);
      }

      return null;
    },
    ...mapGetters(['isDocumentInCart']),
  },
});
</script>

<style lang="scss" scoped>
@import 'mdb-ui-kit/src/scss/bootstrap-rtl-fix/mixins';
@import 'mdb-ui-kit/src/scss/bootstrap-rtl-fix/functions';
@import 'mdb-ui-kit/src/scss/bootstrap-rtl-fix/variables';
@import '@/assets/scss/variables';

.viteos-nav {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border: none;
  background-color: white;
  color: $primary;
  position: relative;
  font-weight: 600;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: box-shadow 0.2s ease-in;
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  span {
    margin-top: 0.3rem;
  }

  &:not(:disabled) {
    &:hover {
      background-color: #F9EEE4;
    }

    &:active,
    &.active {
      background-color: #F9EEE4;
    }
  }
}

.zone-point {
  position: absolute;
  left: calc(var(--zone-point-x, 1px) / calc(var(--zone-original-width) / var(--zone-width)));
  top: calc(var(--zone-point-y, 1px) / calc(var(--zone-original-height) / var(--zone-height)));
}

.zone-point-icon {
  width: 54px;
}

.zone-point-btn:hover {
  color: white;
}

.zone-point-detail-card {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s linear 0s, opacity 250ms ease 0s;
  max-width: 40vw;
  z-index: 2;

  @include media-breakpoint-up(lg) {
    max-width: 30vw;
  }
  @include media-breakpoint-up(xxl) {
    width: 350px;
  }

  .card-img-top {
   max-height: 350px;
    object-fit: contain;
  }
}

.zone-point-detail-card.hidden {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 250ms, opacity 250ms ease 0s;
}

.zone-point-detail-card .card-body {
  max-height: 60vh;
  overflow: auto;
}

.card {
  max-height: 95vh;
}
</style>
