<template>
	<main>
		<router-view v-slot="{Component}">
			<transition name="fade" mode="out-in">
				<component :is="Component" />
			</transition>
		</router-view>
	</main>
</template>

<script lang="ts">
import {captureException} from '@sentry/minimal';
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'App',

	created(): void {
		if (!this.$store.getters.isAuthenticated && sessionStorage.getItem('refresh_token') !== null) {
			this.$store.dispatch('refreshToken', sessionStorage.getItem('refresh_token'));
		}

		this.$store.dispatch('loadFolders').catch(err => captureException(err));
	},
});
</script>

<style>
main {
	height: 100%;
}
</style>
