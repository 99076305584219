<template>
  <div class="card" id="cart">
    <div class="card-body">
      <h5 class="text-primary">Documents à envoyer</h5>
      <section v-if="files.length > 0" class="mt-3">
        <ul class="documents-list mb-0">
          <li v-for="file of files" :key="file.uuid" class="document p-2">
            <div>
              <p class="document-description">{{ file.title }}</p>
            </div>
            <button class="btn btn-link" type="button"
                    @click.prevent.stop="removeFromCart(file)">
              <font-awesome-icon icon="trash" size="lg"/>
            </button>
          </li>
        </ul>
        <hr class="separation-line mb-0">
        <form method="post" @submit.prevent="sendEmail">
          <div class="row align-items-center justify-content-center py-2">
            <div class="col-auto px-4">
              <div class="form-check">
                <input
                    class="form-check-input mt-2"
                    type="radio"
                    name="cart-recipient-gender"
                    id="cart-recipient-gender1"
                    value="ms"
                    required
                    v-model="form.gender"
                />
                <label class="form-check-label mt-2" for="cart-recipient-gender1">
                  Madame</label>
              </div>
            </div>
            <div class="col-auto px-4">
              <div class="form-check">
                <input
                    class="form-check-input mt-2"
                    type="radio"
                    name="cart-recipient-gender"
                    id="cart-recipient-gender2"
                    value="mr"
                    required
                    v-model="form.gender"
                />
                <label class="form-check-label mt-2" for="cart-recipient-gender2">
                  Monsieur</label>
              </div>
            </div>
            <span class="hidden-error mb-0 text-danger" :class="{visible : formErrors.gender}">Veuillez selectionner une option</span>
          </div>
          <mdb-input
              :required="true"
              type="text"
              id="cart-name"
              name="cart-recipient-name"
              label="Nom & prénom"
              v-model="form.name"
          />
          <span class="hidden-error mb-3 text-danger" :class="{visible : formErrors.name}">Veuillez entrer un nom</span>
          <mdb-input
              :required="true"
              type="email"
              id="cart-email"
              name="cart-recipient-email"
              label="Adresse email"
              v-model="form.email"
          />
          <span class="hidden-error mb-3 text-danger" :class="{visible : formErrors.email}">Veuillez entrer une adresse valide</span>
          <div
              class="action-section text-center d-flex justify-content-between align-items-center">
            <template v-if="!emailSent">
            <span class="cancel-action text-primary mb-0 ms-2"
                  @click="closeCart">
              Annuler
            </span>
              <button class="wrapper-button" :disabled="loading">
                <SendEmailButton @click.prevent="sendEmail" :sending="loading"/>
              </button>
            </template>
            <template v-else>
              <span class="close-action text-primary p-2 ps-5 pe-5"
                @click="closeCart"
              >
                Fermer
              </span>
              <span class="text-primary me-2">
                Email envoyé !
              </span>
            </template>
          </div>
        </form>
      </section>
      <p v-else class="mt-5 text-muted text-center">Aucun document disponible</p>
    </div>
    <Snackbar v-model:active="displaySnackbar" :duration="4000">
      <span>Une erreur s'est produite, veuillez réessayer !</span>
    </Snackbar>
  </div>
</template>

<script lang="ts">
import {SharePointDocument} from 'src/typings/entities';
import {defineComponent} from 'vue';
import Input from '@/components/Form/Input.vue';
import Snackbar from '@/components/Snackbar/Snackbar.vue';
import SendEmailButton from "../Other/SendEmailButton.vue";

interface ComponentData {
  loading: boolean;
  displaySnackbar: boolean;
  recipient: {
    gender: string;
    name: string;
    email: string;
  } | null;
  emailSent: boolean,
  form: {
    gender: string,
    name: string,
    email: string,
  },
  formErrors: {
    gender: boolean,
    name: boolean,
    email: boolean,
  }
}

export default defineComponent({
  name: 'SimplifiedCart',

  emits: ['update:show', 'closeCart'],

  components: {
    SendEmailButton,
    'mdb-input': Input,
    Snackbar,
  },

  data(): ComponentData {
    return {
      loading: false,
      displaySnackbar: false,
      recipient: null,
      emailSent: false,
      form: {
        gender : '',
        name: '',
        email: '',
      },
      formErrors: {
        gender : false,
        name: false,
        email: false,
      },
    };
  },

  methods: {
    closeCart() {
      this.$emit('closeCart');
    },
    removeFromCart(document: SharePointDocument): void {
      this.$store.commit('removeFromCart', document);
      if (this.files.length === 0) {
        this.$emit('closeCart')
      }
    },
    formHasErrors() {
      this.formErrors.gender = !(this.form.gender === 'ms' || this.form.gender === 'mr');
      this.formErrors.name = (this.form.name.trim() === "");
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.formErrors.email = !emailRegex.test(this.form.email)

      return(Object.values(this.formErrors).includes(true));
    },
    async sendEmail(event: Event): Promise<void> {
      const formHasErrors = this.formHasErrors();
      if(formHasErrors) {
        return;
      }

      setTimeout(() => {
        this.loading = true;
      },50)

      this.recipient = this.form;

      const request = await fetch(`${import.meta.env.VITE_API_BASE_URI}/api/send-mails`, {
        method: 'POST',
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json',
        },
        body: JSON.stringify({
          from: this.$store.state.user.email,
          recipient: this.recipient,
          documents: this.$store.state.cart.map(d => d['@id']),
        }),
      });

      if (request.ok) {
        await request.json();
        this.emailSent = true;
        this.form.gender = '';
        this.form.name = '';
        this.form.email = '';
      } else {
        const err = await request.json();

        this.displaySnackbar = true;
        this.loading = false;
        throw new Error(err);
      }

      this.loading = false;
    },
  },

  computed: {
    files(): SharePointDocument[] {
      return this.$store.state.cart;
    },
    email(): string {
      return this.$store.state.user.email;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

.separation-line {
  width: calc(100% + 4rem);
  transform: translateX(-2rem);
}

.action-section {
  .cancel-action, .close-action {
    cursor: pointer;
  }

  .close-action{
    background-color: $dark-bg;
    border-radius: 5px;
  }
}

.wrapper-button {
  border: none;
  margin: 0;
  padding: 0;
}

.hidden-error{
  visibility: hidden;
}
.visible {
  visibility: visible !important;
}

#cart {
  display: block;
}

.documents-list {
  list-style-type: none;
  padding-left: 0;

  .document {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    border-radius: 4px;
    transition: background-color 250ms ease;
    cursor: auto;

    & > div {
      flex-grow: 1;
    }

    .document-title {
      font-weight: bold;
      margin-bottom: 0.2rem;
      font-size: 16px;
    }

    .document-description {
      font-weight: lighter;
      font-size: 14px;
      margin-bottom: 0;
    }

    &:hover {
      background-color: rgba(#f58220, 15%);
      transition: background-color 250ms ease;
    }
  }
}
</style>
