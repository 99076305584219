<template>
	<div class="form-outline" ref="input">
		<input
			:type="type"
			:id="id"
			class="form-control"
			:required="required"
			:disabled="disabled"
			@input="onInput($event)"
			:value="modelValue"
			:name="name"
		/>
		<label :for="id" class="form-label">{{ label }}</label>
	</div>
</template>

<script lang="ts">
import {Input} from 'mdb-ui-kit';
import {defineComponent} from 'vue';

interface BaseComponentData {
	input: Input | null;
}

export default defineComponent({
	name: 'Input',

	props: {
		required: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'text',
		},
		name: {
			type: String,
			required: false,
		},
		id: String,
		label: String,
		modelValue: String,
	},

	emits: ['update:modelValue'],

	data(): BaseComponentData {
		return {
			input: null,
		};
	},

	mounted() {
		this.input = new Input(this.$refs.input as HTMLInputElement);
	},

	methods: {
		onInput(event: Event): void {
			this.$emit('update:modelValue', (event.target as HTMLInputElement).value);
		},
	},

	unmounted() {
		this.input?.dispose();
	},
});
</script>
