<template>
  <svg class="email-icon" :class="{active: isActive}"
       width="19" height="15" viewBox="0 0 19 15" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
        d="M17.1 0H1.9C0.855 0 0.00949999 0.84375 0.00949999 1.875L0 13.125C0 14.1562 0.855 15 1.9 15H17.1C18.145 15 19 14.1562 19 13.125V1.875C19 0.84375 18.145 0 17.1 0ZM17.1 3.75L9.5 8.4375L1.9 3.75V1.875L9.5 6.5625L17.1 1.875V3.75Z"
        fill="#737373"/>
  </svg>
</template>

<script>
export default {
  name: "emailIcon",
  props: {
    isActive: {
      type: Boolean,
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables';
  svg.active {
    * {
      fill: $dark-orange;
    }
  }
</style>
