<template>
	<div
		class="modal fade"
		id="galleryImageModal"
		tabindex="-1"
		ref="galleryImageModal"
		aria-label="Zoom"
		:aria-hidden="show ? 'false' : 'true'"
	>
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content rounded-0" :class="{'bg-white': loading}">
				<img v-if="image" :src="getImageUrl(image)" alt="Image" class="img-fluid" />
				<div class="modal-body" v-if="loading || error">
					<div class="d-flex justify-content-center">
						<div class="spinner-border text-primary" role="status" v-if="loading">
							<span class="visually-hidden">Chargement en cours...</span>
						</div>
						<div class="alert alert-danger" role="alert" v-if="error">
							{{ error }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {captureException} from '@sentry/minimal';
import {Modal} from 'mdb-ui-kit';
import {SharePointDocument} from 'src/typings/entities';
import {defineComponent, PropType} from 'vue';

interface ComponentData {
	modal: Modal | null;
	loading: boolean;
	error: string | null;
}

export default defineComponent({
	name: 'GalleryImage',

	emits: ['update:show', 'shown', 'hidden'],

	data(): ComponentData {
		return {
			modal: null,
			loading: true,
			error: null,
		};
	},

	props: {
		show: {
			type: Boolean,
			default: true,
		},
		image: {
			type: Object as PropType<SharePointDocument>,
			required: false,
		},
		imageFilter: {
			type: String as PropType<'fullscreen' | 'product' | 'thumbnail'>,
			default: 'fullscreen',
			required: false,
		},
	},

	mounted(): void {
		this.modal = new Modal(this.$refs.galleryImageModal as HTMLElement, {
			// backdrop: false,
		});

		(this.$refs.galleryImageModal as HTMLElement).addEventListener('hidden.bs.modal', this.onModalHidden);
		(this.$refs.galleryImageModal as HTMLElement).addEventListener('shown.bs.modal', this.onModalShown);
	},

	beforeUnmount(): void {
		this.$emit('update:show', false);
		this.modal?.dispose();

		(this.$refs.galleryImageModal as HTMLElement).removeEventListener('hidden.bs.modal', this.onModalHidden);
		(this.$refs.galleryImageModal as HTMLElement).removeEventListener('shown.bs.modal', this.onModalShown);
	},

	methods: {
		onModalShown(): void {
			this.$emit('update:show', true);
			this.$emit('shown');
		},
		onModalHidden(): void {
			this.$emit('update:show', false);
			this.$emit('hidden');
		},
		onImgLoaded(): void {
			this.loading = false;
		},
		getImageUrl(image: Pick<SharePointDocument, 'base64ServerRelativeUrl'>): string {
			return `${import.meta.env.VITE_API_BASE_URI}/media/cache/resolve/${this.imageFilter}/${
				image.base64ServerRelativeUrl
			}`;
		},
	},

	watch: {
		show(newValue: boolean): void {
			if (newValue === true && this.image !== undefined) {
				this.loading = true;
				this.error = null;
				this.modal?.show();

				// Manually load the image to react to the "load" event and disable spinner.
				const image = new Image();
				image.addEventListener(
					'load',
					() => {
						this.onImgLoaded();
					},
					{
						once: true,
					},
				);
				image.addEventListener('error', (error: ErrorEvent) => {
					this.error = error.error;
					this.loading = false;
					captureException(error.error);
				});
				image.src = this.getImageUrl(this.image);
			} else {
				this.modal?.hide();
			}
		},
	},
});
</script>

<style lang="scss" scoped>
@import 'mdb-ui-kit/src/scss/bootstrap-rtl-fix/functions';
@import 'mdb-ui-kit/src/scss/free/functions';
@import 'mdb-ui-kit/src/scss/free/variables';
@import '@/assets/scss/variables';

.modal-backdrop {
	z-index: #{$zindex-toast + 1};
}
.modal {
	z-index: #{$zindex-toast + 2};

	.modal-dialog {
		max-width: 80vw;

		.modal-content {
			background-color: transparent;
		}
	}
}
</style>
