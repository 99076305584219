<template>
  <div class="send-card card-body p-2 ps-4 pe-4 d-inline-flex text-center text-primary align-items-center">
    <template v-if="!sending">
      <p class="mb-0 me-4">Envoyer</p>
      <email-icon :is-active="true"/>
      <p class="m-0 ms-1">({{$store.state.cart.length}})</p>
    </template>
    <template v-else>
      <p class="mb-0 me-4">En cours</p>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </template>
  </div>
</template>

<script lang="ts">
import EmailIcon from "../SvgIcons/emailIcon.vue";
export default {
  name: "SendEmailButton",
  components: {EmailIcon},
  props: {
    sending: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables';
.send-card {
  background-color: $dark-bg;
  cursor: pointer;
  border-radius: 5px;
}
</style>
