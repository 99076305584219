import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw} from 'vue-router';
import {store} from './store';
import SplashScreen from '@/components/SplashScreen.vue';
import Documents from '@/components/HouseTour/Documents.vue';
import Presentation from '@/components/HouseTour/Presentation.vue';

declare module 'vue-router' {
	interface RouteMeta {
		anonymous?: boolean;
		requiresAuth?: boolean;
	}
}

export const routes: RouteRecordRaw[] = [
	{
		path: '/',
		component: SplashScreen,
		name: 'homepage',
		meta: {
			anonymous: true,
		},
	},
	{
		path: '/dashboard',
		component: () => import('@/components/Homepage.vue'),
		name: 'dashboard',
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/house-tour/:type',
		component: () => import('@/components/HouseTour/HouseTour.vue'),
		name: 'house-tour',
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: '',
				component: Presentation,
				name: 'house-tour-presentation',
			},
		],
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		redirect: '/',
	},
];

const vueRouter = createRouter({
	history: createWebHistory(),
	routes,
});

vueRouter.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!store.getters.isAuthenticated) {
			next({
				name: 'homepage',
				params: {nextUrl: to.fullPath},
			});
		} else {
			next();
		}
	} else if (to.matched.some(record => record.meta.anonymous)) {
		if (!store.getters.isAuthenticated) {
			next();
		} else {
			next({name: 'dashboard'});
		}
	} else {
		next();
	}
});

export const router = vueRouter;
