<template>
	<transition name="snackbar" appear>
		<aside class="snackbar" :class="snackbarClasses" v-if="active">
			<div class="snackbar-content">
				<slot />
			</div>
		</aside>
	</transition>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'Snackbar',

	props: {
		active: Boolean,
		position: {
			type: String,
			required: false,
			default: 'center',
			validator(value: string): boolean {
				return ['center', 'left'].includes(value);
			},
		},
		duration: {
			type: Number,
			required: false,
			default: 4000,
			validator(value: number): boolean {
				return value >= 0;
			},
		},
	},

	emits: ['update:active', 'opened', 'closed'],

	computed: {
		snackbarClasses(): object {
			return {
				['position-' + this.position]: true,
			};
		},
	},

	watch: {
		active(isActive) {
			if (isActive) {
				this.$emit('opened');
				setTimeout(() => {
					this.$emit('update:active', false);
				}, this.duration);
			} else {
				this.$emit('closed');
			}
		},
	},
});
</script>

<style lang="scss">
@import 'mdb-ui-kit/src/scss/bootstrap-rtl-fix/mixins';
@import 'mdb-ui-kit/src/scss/bootstrap-rtl-fix/functions';
@import 'mdb-ui-kit/src/scss/bootstrap-rtl-fix/variables';
@import '@/assets/scss/variables';

.snackbar {
	background-color: #323232;
	color: white;
	min-width: 288px;
	max-width: 568px;
	min-height: 48px;
	max-height: 80px;
	padding: 14px 24px;
	display: flex;
	align-items: center;
	position: fixed;
	z-index: 1000;
	border-radius: 2px;
	transition: 0.4s ease;
	box-shadow: $box-shadow;
	will-change: background-color, color, opacity, transform;

	&.position-center {
		margin: 0 auto;
		right: 0;
		bottom: 1rem;
		left: 0;
		&.snackbar-enter,
		&.snackbar-leave-active {
			transform: translate3D(0, calc(100% + 8px), 0);
		}
	}
	&.position-left {
		bottom: 24px;
		left: 24px;
		&.snackbar-enter,
		&.snackbar-leave-active {
			transform: translate3D(0, calc(100% + 32px), 0);
		}
	}
}
.snackbar-enter,
.snackbar-leave-active {
	opacity: 0;
	.snackbar-content {
		opacity: 0;
	}
}
.snackbar-content {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	transition: opacity 0.38s ease;

	span {
		word-break: break-all;
	}
}
</style>
