<template>
	<section>
    <div>
      <div v-if="loading">
        <div class="d-flex p-3 flex-column align-items-center">
          <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
          <span>Chargement des documents...</span>
        </div>
      </div>
      <div class="documents-container pt-3 mb-2" v-else>
        <section v-for="folder of folders" :key="folder.uuid">
          <h6 class="text-primary ms-4">{{ folder.name }}</h6>
          <ul class="documents-list">
            <li
                v-for="file of filterFiles(folder.files)"
                :key="file.uuid"
                class="document"
            >
              <div>
                <p class="document-description ms-3">{{ file.title }}</p>
              </div>
              <button @click="onDocumentClick(file)" class="btn btn-link p-2 btn-no-bg" >
                <email-icon :is-active="$store.getters.isDocumentInCart(file.base64ServerRelativeUrl)" />
              </button>
              <button @click="$emit('showDocument', getDownloadUrl(file))" class="btn btn-link p-2 btn-no-bg" >
                <svg class="eye-icon" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.4993 4.6875C7.29102 4.6875 2.8431 7.92708 1.04102 12.5C2.8431 17.0729 7.29102 20.3125 12.4993 20.3125C17.7077 20.3125 22.1556 17.0729 23.9577 12.5C22.1556 7.92708 17.7077 4.6875 12.4993 4.6875ZM12.4993 17.7083C9.62435 17.7083 7.29102 15.375 7.29102 12.5C7.29102 9.625 9.62435 7.29167 12.4993 7.29167C15.3743 7.29167 17.7077 9.625 17.7077 12.5C17.7077 15.375 15.3743 17.7083 12.4993 17.7083ZM12.4993 9.375C10.7702 9.375 9.37435 10.7708 9.37435 12.5C9.37435 14.2292 10.7702 15.625 12.4993 15.625C14.2285 15.625 15.6243 14.2292 15.6243 12.5C15.6243 10.7708 14.2285 9.375 12.4993 9.375Z" fill="#737373"/>
                </svg>
              </button>
            </li>
          </ul>
          <p v-if="filterFiles(folder.files).length === 0" class="text-muted text-center">
            Aucun document disponible pour ce dossier
          </p>
        </section>
        <p v-if="folders.length === 0" class="text-muted text-center">Aucun document disponible</p>
        <div
            class="card send-email-container"
            v-show="$store.state.cart.length > 0"
            @click.prevent="$emit('showCart')"
        >
          <SendEmailButton />
        </div>
      </div>
    </div>
	</section>
</template>

<script lang="ts">
import {Service, SharePointDocument, SharePointFolder} from 'src/typings/entities';
import {defineComponent, PropType} from 'vue';
import {mapGetters} from 'vuex';
import GalleryImage from '@/components/HouseTour/GalleryImage.vue';
import Snackbar from '@/components/Snackbar/Snackbar.vue';
import EmailIcon from "../SvgIcons/emailIcon.vue";
import SendEmailButton from "../Other/SendEmailButton.vue";

interface ComponentData {
	filter: string | undefined;
	displaySnackbar: boolean;
	latestDocument: SharePointDocument | undefined;
	loading: boolean;
	imageLoading: boolean;
	showZoom: boolean;
}

export default defineComponent({
	name: 'SimplifiedDocuments',

	components: {
    SendEmailButton,
    EmailIcon,
		GalleryImage,
		Snackbar,
	},

	props: {
		service: {
			type: Object as PropType<Service>,
			required: true,
		},
	},

  emits: ['showDocument', 'documentsLoaded', 'showCart'],

	data(): ComponentData {
		return {
			filter: undefined,
			displaySnackbar: false,
			latestDocument: undefined,
			loading: true,
			imageLoading: true,
			showZoom: false,
		};
	},

	mounted(): void {
		this.loading = true;
		this.imageLoading = true;
		this.$store.dispatch('loadFoldersForService', this.service).then(() => {
      this.$emit('documentsLoaded')
			this.loading = false;
		});
	},

	methods: {
		onSubmit(event: Event): void {
			// do nothing
		},
		onDocumentClick(document: SharePointDocument): void {
      if(!this.$store.getters.isDocumentInCart(document.base64ServerRelativeUrl)) {
        this.$store.commit('addToCart', document);
        this.latestDocument = document;
        // this.displaySnackbar = true;
      } else {
        this.$store.commit('removeFromCart', document);
        // this.displaySnackbar = false;
        this.latestDocument = undefined;
      }
		},
		getDownloadUrl(document: Pick<SharePointDocument, 'base64ServerRelativeUrl'>): string {
			return `${import.meta.env.VITE_API_BASE_URI}/documents/${document.base64ServerRelativeUrl}/download`;
		},
		filterFiles(files: SharePointDocument[]): SharePointDocument[] {
			if (this.filter === undefined || this.filter.length === 0 || files.length === 0) {
				return files;
			}

			return files.filter(f => f.name.toLowerCase().includes((this.filter as string).toLowerCase()));
		},
	},

	computed: {
		...mapGetters(['isDocumentInCart', 'getFolderForService']),
		documentFolder(): SharePointFolder | undefined {
			const folder: SharePointFolder | undefined = this.getFolderForService(this.service.title.rendered);

			if (folder === undefined) {
				return undefined;
			}

			// return folders from this level instead of subfolders from the documents folder
			// return folder.folders.find(f => f.name.toLowerCase().includes('documents'));
      return folder;
		},
		folders(): SharePointFolder[] {
			// Search the "Documents" folder
			const documentsFolder = this.documentFolder;

			if (documentsFolder === undefined) {
				return [];
			}

			return documentsFolder.folders;
		},
	},
});
</script>

<style lang="scss" scoped>
@import 'mdb-ui-kit/src/scss/bootstrap-rtl-fix/functions';
@import 'mdb-ui-kit/src/scss/free/functions';
@import 'mdb-ui-kit/src/scss/free/variables';
@import '@/assets/scss/variables';

.btn-no-bg{
  &:hover, &:focus {
    background-color: transparent;
  }
}

.documents-container {
  height: fit-content;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  .send-email-container {

    position: fixed;
    bottom: 7rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.documents-list {
	list-style-type: none;
	padding-left: 0;

	.document {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 1rem;
		border-radius: 4px;
		transition: background-color 250ms ease;
		cursor: default;

		& > div {
			flex-grow: 1;
		}

		.document-title {
			font-weight: bold;
			margin-bottom: 0.2rem;
			font-size: 16px;
			word-break: break-all;
		}
		.document-description {
			font-weight: lighter;
			font-size: 14px;
			margin-bottom: 0;
		}

		&:hover {
			background-color: rgba(#f58220, 15%);
			transition: background-color 250ms ease;
		}
	}
}
</style>
