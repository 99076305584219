<template>
  <div class="frame-container">
    <div class="main-iframe">
      <span v-show="showLoader" class="spinner-border text-primary" role="status" aria-hidden="true"></span>
      <iframe :src="`${src_url}#toolbar=0&navpanes=0`" @load="showLoader = false"/>
      <div class="close-btn" @click="$emit('close')">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IFramePopup",
  props: {
    src_url: String,
  },
  emits: ['close'],
  data() {
    return {
      showLoader: true,
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables';

.frame-container {
  z-index: 10;
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  .main-iframe {
    position: relative;

    .spinner-border {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    iframe, embed {
      margin-left: auto;
      margin-right: auto;
      height: 90vh;
      width: 90vw;
      border-radius: 10px;
      background-color: white;
    }

    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 50%;
      padding: 8px;
      svg {
        width: 24px;
        height: 24px;
        fill: white;
      }
      background-color: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(50%) translateY(-50%);
      cursor: pointer;
    }
  }
}

</style>
